/* @font-face {
  font-family: 'MontserratAlternates';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf') format('truetype');
} */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}



@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf');
}


body {
  margin: 0;
  font-family: 'OpenSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
